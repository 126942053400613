import React from "react";

import { getBaseURL } from '../../services/BaseURL'
import { useState } from "react";
import { useEffect } from "react";
const BASE_URL = getBaseURL()
const axios = require('axios')
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import LoaderComponent from "../Loader/LoaderComponent";
import ImageViewer from 'react-simple-image-viewer';
import { useCallback } from "react";
import Slide from 'react-reveal/Slide';
export default function GalleryComponent() {

  let [data,setData]=useState([])
  let [ready,setReday]=useState(false)
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
    useEffect(()=>{
        let url = BASE_URL + 'gallery'
        axios.get(url)
            .then(function(response) {
                if (response.status === 200) {
                  setData(response.data)
                  setReday(true)
                } else {
                  setData([])
                }
            })
            .catch(function(error) {
              setData([])
            });
        
        
    },[])

    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    
    <>
    <ReactPlaceholder customPlaceholder={<LoaderComponent></LoaderComponent>} ready={ready} rows={25} showLoadingAnimation  color='#E0E0E0'>
    <div className="main-content mb-5">
    <div className='product-page-heading mt-4 bg-gray'>
        <div className="row">
            <div className="col-12">
                <Slide top>
                <h2 className='text-center'>Image Gallery</h2>
                </Slide>
            </div>
        </div>
    </div>
    <div className='product-section mt-4'>
      <Slide right>
        <div className="row">
  
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <div className="row">
                        {
                            data?.map((item,i)=>
                            <div className="col-12 col-md-3 col-lg-4 col-xl-4 col-sm-12 col-xm-12 mt-2" >
                        <a href="#" className="product-card-wrapper" onClick={ () => openImageViewer(i) }>
                            <div className="product-card">
                               
                                <div className="product-card-image mt-1">
                                    <img style={{height:"18rem"}} src={item} alt="" key={ i }/>
                                    
                                </div>
                                
                                
                            </div>
                        </a>
                    </div>
                            )
                        }
                        

                    
                    
                   
                </div>
                
            </div>
        </div>
      </Slide>
        
    </div>
    {isViewerOpen && (
        <ImageViewer
          src={ data }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
        />
      )}
</div>
  </ReactPlaceholder>
    
    </>
  );
}
