import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { getBaseURL } from '../../services/BaseURL'
import { useState } from "react";
import { useEffect } from "react";
const BASE_URL = getBaseURL()
const axios = require('axios')
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import LoaderComponent from "../Loader/LoaderComponent";
import cali3 from './../../assets/images/cali3.jpg'
import Slide from 'react-reveal/Slide';
export default function ProjectComponent() {
  let { id } = useParams();
  let [data,setData]=useState([])
  let [project,setProject]=useState({})
  let [ready,setReday]=useState(false)
    useEffect(()=>{
        let url = BASE_URL + 'projectitem/'+id
        axios.get(url)
            .then(function(response) {
                if (response.status === 200) {
                  setData(response.data.data)
                  setProject(response.data.project)
                  setReday(true)
                } else {
                  setData([])
                }
            })
            .catch(function(error) {
              setData([])
            });
        
        
    },[])
  return (
    
    <>
    <ReactPlaceholder customPlaceholder={<LoaderComponent></LoaderComponent>} ready={ready} rows={25} showLoadingAnimation  color='#E0E0E0'>
  <Slide top>
    <div className="foreign-tiles-header header-cali-height" style={{backgroundImage:`url(${cali3})`,backgroundSize:"cover"}}>

        <div className="header-overlay header-cali-height" style={{backgroundColor:"rgb(255 255 255 / 48%)"}}>
          <h1 className="p-2 d-flex align-items-center justify-content-center h-100 header-title-project" style={{opacity:"1"}}>{project?project.title:""}</h1>
        </div>
        
    </div>
    </Slide>
    <Slide right>
    <div className="container-fluid middle-slider-section mb-5 pt-5" id="project">
        {
          data?.map((item,i)=>
            item.is_left ===1? <>
            
              <div className="middle-slider-section-d-block">
                <div className="row p-5 custom-middle-slider-p-5">
                  <div className="col-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="middle-slider-section-left">
                    
                      <div className="middle-slider-section-left-div">
                        <h3 className="mt-5">{item.title}</h3>
                        <p className="text-dark mt-2 tex-justify p-50 w-60 ">
                          {item.description}
                        </p>
                        <br />
                        <small style={{ color: "black" }}>
                          {item.extra_description}
                        </small>{" "}
                        <br /> <br />
                        
                        
                      </div>

                    </div>
                  </div>
                  <div className="col-6 col-md-6 col-lg-6 col-xl-6">

                    <div className="middle-slider-right">
                      <img src={item.image} alt="" style={{height:"40rem",objectFit:"cover"}}/>
                    </div>

                  </div>
                </div>
              </div>
              <div className="middle-slider-section-d-none p-4">
                <div className="row">
                  <div className="col-12  col-md-12 col-lg-6 col-xl-6 col-sm-12">
                    <div className="middle-slider-right">
                      <img className="mt-3" src={item.image} alt="" style={{height:"30rem",objectFit:"cover"}}/>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6 col-xl-6 col-sm-12">
                    <div className="middle-slider-section-left">
                      <div className="middle-slider-section-left-div">
                        <h3 className="mt-3">{item.title}</h3>
                        <p className="text-dark mt-2 tex-justify p-50 w-60 ">
                        {item.description}
                        </p>
                        <br />
                        <small style={{ color: "black" }}>
                        {item.extra_description}
                        </small>{" "}
                        <br /> <br />
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>:<> <div className="middle-slider-section-d-block">
                <div className="row p-5 custom-middle-slider-p-5">
                <div className="col-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="middle-slider-right">
                      <img src={item.image} alt="" style={{height:"40rem",objectFit:"cover"}}/>
                    </div>
                  </div>
                  <div className="col-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="middle-slider-section-left">
                      <div className="middle-slider-section-left-div">
                        <h3 className="mt-5">{item.title}</h3>
                        <p className="text-dark mt-2 tex-justify p-50 w-60 ">
                        {item.description}
                        </p>
                        <br />
                        <small style={{ color: "black" }}>
                        {item.extra_description}
                        </small>{" "}
                        <br /> <br />
                        
                        
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="middle-slider-section-d-none p-4">
                <div className="row">
                  <div className="col-12  col-md-12 col-lg-6 col-xl-6 col-sm-12">
                    <div className="middle-slider-right">
                      <img className="mt-3" src={item.image} alt="" style={{height:"30rem",objectFit:"cover"}}/>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6 col-xl-6 col-sm-12">
                    <div className="middle-slider-section-left">
                      <div className="middle-slider-section-left-div">
                        <h3 className="mt-3">{item.title}</h3>
                        <p className="text-dark mt-2 tex-justify p-50 w-60 ">
                        {item.description}
                        </p>
                        <br />
                        <small style={{ color: "black" }}>
                        {item.extra_description}
                        </small>{" "}
                        <br /> <br />
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div></>
        )}
        
      
      
    </div>
    </Slide>
    </ReactPlaceholder>
    
    </>
  );
}
