import React from "react";

import { getBaseURL } from '../../services/BaseURL'
import { useState } from "react";
import { useEffect } from "react";
const BASE_URL = getBaseURL()
const axios = require('axios')
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import LoaderComponent from "../Loader/LoaderComponent";
import ReactPlayer from 'react-player'
import { Button, Modal } from 'react-bootstrap';
import '../../assets/css/style.css';
import Slide from 'react-reveal/Slide';
export default function VideoComponent() {

  let [data,setData]=useState([])
  let [ready,setReday]=useState(false)
  
    useEffect(()=>{
        let url = BASE_URL + 'video'
        axios.get(url)
            .then(function(response) {
                if (response.status === 200) {
                  setData(response.data)
                  setReday(true)
                } else {
                  setData([])
                }
            })
            .catch(function(error) {
              setData([])
            });
        
        
    },[])

    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState({});

  const handleClose = () => {
      setShow(false)
      setModalData({})
  };
  const handleShow = (data) =>{ 
      setShow(true)
      setModalData(data)
  };
  return (
    
    <>
    <ReactPlaceholder customPlaceholder={<LoaderComponent></LoaderComponent>} ready={ready} rows={25} showLoadingAnimation  color='#E0E0E0'>
    <div className="main-content mb-5">
    <div className='product-page-heading mt-4 bg-gray'>
        <div className="row">
            <div className="col-12">
                <Slide top>
                <h2 className='text-center'>Video Gallery</h2>
                </Slide> 
            </div>
        </div>
    </div>
    <div className='product-section mt-4'>
        <div className="row">
  
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <Slide right>
                <div className="row">
                        {
                            data?.map((item,i)=>
                            <div className="col-12 col-md-4 col-lg-3 col-xl-3 col-sm-12 col-xm-12 mt-2" >
                        <a href="#" className="product-card-wrapper" onClick={()=>handleShow(item)}>
                            <div className="product-card">
                               
                                <div className="product-card-image mt-1">
                                    <img style={{height:"18rem"}} src={item.image} alt="" key={ i }/>
                                    
                                </div>
                                <div className="card-info">
                                                    <div className="product-name mt-4 text-muted text-uppercase"><a href="#" className="text-of-product text-muted">{item.title}</a></div>
                                                    <div className="product-name product-name-2 mt-4"><a href="view.html" className="text-of-product"></a></div>
                                </div>
                                
                            </div>
                        </a>
                    </div>
                            )
                        }
                        

                    
                    
                   
                </div>
                </Slide>   
            </div>
        </div>

        
    </div>
    
</div>

  </ReactPlaceholder>
      <Modal show={show} onHide={handleClose} size="lg" className="custom-modal" aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton >
        
      </Modal.Header>
      <Modal.Body >
          <div className="row">
              <div className="col-12">
                <ReactPlayer playing={true} width={"100%"}  url={modalData.link} controls={true}/>
              </div>
              
          </div>
      </Modal.Body>
    
    </Modal>
    </>
  );
}
