import { createSlice } from '@reduxjs/toolkit'

export const SliderSlice = createSlice({
    name: 'slider',
    initialState: {
        slider: [],
    },
    reducers: {
        getSlider: (state, action) => {
            state.slider = action.payload
        },


    },
})

export const { getSlider } = SliderSlice.actions

export default SliderSlice.reducer