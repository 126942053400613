import React from 'react'
import HeaderComponent from '../components/Header/HeaderComponent'
import FooterComponent from '../components/Footer/FooterComponent'
import GalleryComponent from './../components/Home/GalleryComponent';
import { useState } from 'react';
import { useEffect } from 'react';
import LoaderComponent from '../components/Loader/LoaderComponent';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

import { getBaseURL } from '../services/BaseURL'
const BASE_URL = getBaseURL()
const axios = require('axios')


export default function ImageGalleryPage() {
  let [ready,setReday]=useState(false)
  useEffect(()=>{
    let url = BASE_URL + 'header'
      
      axios.get(url)
          .then(function(response) {
              if (response.status === 200) {
                setReday(true)
              } else {
                setReday(false)
              }
          })
          .catch(function(error) {
              setReday(false)
          });
          
  },[])
  return (
    <>
    <ReactPlaceholder customPlaceholder={<LoaderComponent></LoaderComponent>} ready={ready} rows={25} showLoadingAnimation  color='#E0E0E0'>
    <HeaderComponent></HeaderComponent>
    <main>
        <div className="container-fluid">
            <div className="main-content">
            
                
                <GalleryComponent></GalleryComponent>
                
                
            </div>
        </div>
    </main>
    <FooterComponent></FooterComponent>
    </ReactPlaceholder>
    </>
  )
}
