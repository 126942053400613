import React from 'react'
const axios = require('axios')
import { getBaseURL } from '../../services/BaseURL'
import { useEffect } from 'react'
import { useState } from 'react'
const BASE_URL = getBaseURL()
import { NavLink } from 'react-router-dom'
import '../../assets/css/style.css';
import Slide from 'react-reveal/Slide';
import { SiAffinityphoto } from "react-icons/si";
import {BsArrowRight} from 'react-icons/bs'
export default function ForeignTilesSection() {
    let [data,setData]=useState([])
    let [ready,setReday]=useState(false)
    useEffect(()=>{
        let url = BASE_URL + 'foreign/tilesByLimit'
        axios.get(url)
            .then(function(response) {
                if (response.status === 200) {
                    setData(response.data)
                    setReday(true)
                } else {
                    setData([])
                }
            })
            .catch(function(error) {
                setData([])
            });
        
    },[])

  return (
    <>
    <div className="shop-by-section mt-5 bg-white py-5"  >
    
    <Slide left><h3 className="text-center">Explore Our Foreign Tiles</h3>
    <p className='text-dark text-center'>Explore our explosive foreign tiles.</p></Slide>
    <div className="row gx-0">
        <div className="col-12 md-lg-3 col-md-6 col-xl-3 col-sm-12">
        <Slide left>
            <div className='mt-4 home-foreign-tiles-card-container-2'>
                <NavLink to="/foreign-tiles" style={{ textDecoration: "none" }} >
                    <div className="home-foreign-tiles-card mb-4">
                        <div className="mt-2">
                            <div className="">
                                <div className="">
                                    <div className=" mt-1">

                                            
                                                <div className="" style={{ position: "relative" }}>
                                                <div className="h-100 d-flex justify-content-center align-items-center" >
                                                <div className="load-more-div">
                                                <div className="h-100 d-flex justify-content-center align-items-center">
                                                    <div >
                                                    <BsArrowRight className="d-block m-auto see-more-icon text-dark"></BsArrowRight>
                                                    <b className="see-mnore-text text-dark">Explore More</b>
                                                    </div>
                                                    
                                                </div>
                                                </div>
                                            </div>
                                                </div>
                                        
                                        
                                            
                                            
                                            
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>
            </Slide>
        </div>
        <div className="col-12 md-lg-9 col-md-6 col-xl-9 col-sm-12">
        <Slide left>
            <div className=" mt-4 home-foreign-tiles-card-container" style={{margin:"auto"}}>
        
            { data?.map((item,i)=>
                <NavLink className="home-foreign-tiles-card" to="/foreign-tiles" style={{textDecoration:"none"}}  >
                    <div className="" >
                        <img src={item.image} alt="" srcset="" style={{height:"330px",minWidth:"300px",borderRadius: "16px"}} />
                        <div class="card__content mt-3">
                            <p class="card__category">{item.origin}</p>
                            <h3 class="card__heading">{item.title}</h3>
                        </div>
                    </div>
                </NavLink>
                
                
                )}
            </div>
            </Slide>
        </div>
    </div>

    
</div>
</>
  )
}
