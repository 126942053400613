import React from "react";
import { getBaseURL } from "../../services/BaseURL";
import { useState } from "react";
import { useEffect } from "react";
const BASE_URL = getBaseURL();
const axios = require("axios");
import { BsTelephoneFill, BsFillGeoAltFill } from "react-icons/bs";
import { TbLocation } from "react-icons/tb";
import "../../assets/css/style.css";
import { FaArrowCircleUp } from "react-icons/fa";
import Slide from 'react-reveal/Slide';
export default function FooterComponent() {
  let [logo, setLogo] = useState({});
  let [address, setAddress] = useState([]);
  let [social, setSocial] = useState([]);

  let [ready, setReday] = useState(false);
  useEffect(() => {
    let url = BASE_URL + "footer/logo";
    let urlTwo = BASE_URL + "footer/address";
    let urlThree = BASE_URL + "footer/social";
    axios
      .get(url)
      .then(function (response) {
        if (response.status === 200) {
          setLogo(response.data);
        } else {
          setLogo([]);
        }
      })
      .catch(function (error) {
        setLogo([]);
      });
    axios
      .get(urlTwo)
      .then(function (response) {
        if (response.status === 200) {
          setAddress(response.data);

          setReday(true);
        } else {
          setAddress([]);
        }
      })
      .catch(function (error) {
        setAddress([]);
      });
    axios
      .get(urlThree)
      .then(function (response) {
        if (response.status === 200) {
          setSocial(response.data);
        } else {
          setSocial([]);
        }
      })
      .catch(function (error) {
        setSocial([]);
      });
  }, []);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <div class="horizontal-scroll2"><div class="horizontal-scroll">
    <footer className="wow fadeInUp page-footer font-color pt-5" id="footer">
    <Slide bottom>
      <div className="container-fluid text-center text-md-left">
        <div className="row mt-4">
          <div className="col-sm-12 col-md-5">
            <center>
              <div className="row mb-2">
                <div className="col-12">
                  <div style={{ width: "200px", height: "60px" }}>
                    <img
                      src={logo ? logo.logo : ""}
                      alt=""
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        borderRadius: "12%",
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <span
                    className="mt-1"
                    style={{ color: "#afa1a1", fontSize: "17px" }}
                  >
                    Stay Connected With Us:
                  </span>
                </div>
                <div className="col-12">
                  <div>
                    {social?.map((item, i) => (
                      <a
                        key={item.id}
                        href={item.link}
                        target="_blank"
                        className="p-1"
                        style={{ fontSize: "2rem" }}
                      >
                        <img
                          src={item.icon}
                          className="rounded"
                          alt=""
                          style={{ width: "2.5rem" }}
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </center>
          </div>
          <div className="col-sm-12 col-md-7">
            <div className="row">
              {address?.map((item, i) => (
                <div className="col-md-4">
                  <div className="footer-sub-item">
                    <a
                      title="Google Maps"
                      style={{
                        color: "#afa1a1",
                        fontSize: "1.3rem",
                        color: "#afa1a1",
                        fontWeight: "600",
                      }}
                      href={item.map}
                      target="_blank"
                    >
                      <TbLocation></TbLocation>{" "}
                      <span style={{ fontSize: "1.1rem" }}>Branch {(i+1)}</span>
                    </a>{" "}
                    <br></br>
                    <a style={{ color: "#afa1a1" }}>
                      {item.phone} <br />
                      {item.location} <br />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright text-center py-3">
        <span className="mt-1">Design & Developed by&nbsp;</span>
        <a className="text-warning" href="https://softrear.com/" target="_blank">
          <img src="https://softrear.com/logo.png" width="120" height="45" alt="" style={{position: "relative",
          bottom: "0.4rem"}}/>
        </a>
      </div>

      <FaArrowCircleUp
        class="scrollToBtn"
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      />
      </Slide>
    </footer>
    </div></div>
  );
}
