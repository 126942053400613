import { Fragment } from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import HomePage from './pages/HomePage';
import { ToastContainer } from 'react-toastify';

import FullscreenLoader from './components/MasterLayout/FullscreenLoader';
import ProductPage from './pages/ProductPage';
import ProjectPage from './pages/ProjectPage';
import ForeignTilesPage from './pages/ForeignTilesPage';
import ImageGalleryPage from './pages/ImageGalleryPage';
import VideoPage from './pages/VideoPage';

function App() {

      return(
        <Fragment>
      
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<HomePage/>} />
            <Route exact path="/products/:slug" element={<ProductPage/>} />
            <Route exact path="/project/:id" element={<ProjectPage/>} />
            <Route exact path="/foreign-tiles" element={<ForeignTilesPage/>} />
            <Route exact path="/image-gallery" element={<ImageGalleryPage/>} />
            <Route exact path="/video-gallery" element={<VideoPage/>} />
          </Routes>
        </BrowserRouter>
        <FullscreenLoader/>
        <ToastContainer />
      </Fragment>
      )
    
}

export default App;
