class BaseURL {
    getBaseURL() {
        // return "http://localhost:8000/api/"
        // return "https://apitilesshop.softrear.com/api/"
        return "https://admin.tilescornerbd.com/api/"
    }
}

export const {
    getBaseURL
} = new BaseURL()