import React,{Suspense,lazy} from 'react'
import { useRef,useLayoutEffect } from 'react'
import HeaderComponent from '../components/Header/HeaderComponent'
import FooterComponent from '../components/Footer/FooterComponent'
import ProjectComponent from '../components/Home/ProjectComponent'
import { useState } from 'react'
import { useEffect } from 'react'

import LoaderComponent from '../components/Loader/LoaderComponent';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { getBaseURL } from '../services/BaseURL'
const BASE_URL = getBaseURL()
const axios = require('axios')
export default function ProjectPage() {
   
  let [ready,setReday]=useState(false)
      useEffect(()=>{
        let url = BASE_URL + 'header'
          
          axios.get(url)
              .then(function(response) {
                  if (response.status === 200) {
                    setReday(true)
                  } else {
                    setReday(false)
                  }
              })
              .catch(function(error) {
                  setReday(false)
              });
              
      },[])
    
  
  return (
    <>
    <ReactPlaceholder customPlaceholder={<LoaderComponent></LoaderComponent>} ready={ready} rows={25} showLoadingAnimation  color='#E0E0E0'>
    <HeaderComponent></HeaderComponent>
    <main>
        <div className="">
            <div className="main-content">
            
                
            <ProjectComponent></ProjectComponent>
                
                
            </div>
        </div>
    </main>
    <FooterComponent></FooterComponent>
    </ReactPlaceholder>
    </>
  )
}
