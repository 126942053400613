import React from 'react'
import { useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import {BiUserVoice,BiVector,BiServer,BiAward,BiEnvelope, BiAbacus,BiVideo,} from "react-icons/bi";
import { BsTelephoneFill,BsBoxSeam } from "react-icons/bs";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { getBaseURL } from '../../services/BaseURL'
import { useState } from "react";
import { useEffect } from "react";
import LoaderComponent from '../Loader/LoaderComponent';
import { NavHashLink  } from 'react-router-hash-link';
const BASE_URL = getBaseURL()
const axios = require('axios')
import {Helmet} from "react-helmet";
export default function HeaderComponent() {
    let sideNavRef,overlayRef=useRef();
    const domainName = window.location.hostname;
    const MenuBarClickHandler = () => {
        let sideNav = sideNavRef;
        let overlay = overlayRef
        if (sideNav.classList.contains("sideNavOpen")) {
            sideNav.classList.add("sideNavClose");
            sideNav.classList.remove("sideNavOpen");
            overlay.classList.remove('contentOverLay')
           
        } else {
            sideNav.classList.remove("sideNavClose");
            sideNav.classList.add("sideNavOpen");
            overlay.classList.add('contentOverLay')
        }
    };

    let [data,setData]=useState([])
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    let [ready,setReday]=useState(true)
      useEffect(()=>{
          let url = BASE_URL + 'header'
          
          axios.get(url)
              .then(function(response) {
                  if (response.status === 200) {
                    setData(response.data)
                    setReday(true)
                  } else {
                      setLogo([])
                  }
              })
              .catch(function(error) {
                  setLogo([])
              });
              if(location.pathname=='/'){
                setIsScrolled(true);
                function handleScroll() {
                    
                    if (window.pageYOffset > 40) {
                      setIsScrolled(false);
                      
                    } else {
                      setIsScrolled(true);
    
                    }
                  }
                  window.addEventListener('scroll', handleScroll);
                  return () => {
                    window.removeEventListener('scroll', handleScroll);
                  };
            }
              
              
        },[]);

        
      
  return (
    <>
    <ReactPlaceholder customPlaceholder={<LoaderComponent></LoaderComponent>} ready={ready} rows={25} showLoadingAnimation  color='#E0E0E0'>
        <Helmet>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content={data.meta_description} />
            <link rel="icon" type="image/x-icon" href={data.logo}></link>
            <title>{data.title}</title>
        </Helmet>
    <header className='shadow-sm'>
    
    <div className="top-bar shadow">
            <div className="custom-container">
                <div className="d-sm-block d-md-none">
                    <div className="row">
                        <div className="col-4 col-md-3 col-lg-3 col-xl-3 col-sm-4">
                            <NavLink to="/" className="logo-text" >
                            <div className="logo-img" >
                                <img src={data?data.logo:""} style={{maxHeight:"95%",maxWidth:"95%"}}  alt="logo"/>
                            </div>
                            
                            </NavLink>
                        </div>
                        <div className="col-6 col-md-4 col-lg-6 col-xl-6 col-sm-6 search-section">
                            <div className="input-group search-bar">
                            
                            </div>
                        </div>
                        <div className="col-2 three-bar">
                            <i className="fa fa-bars" onClick={MenuBarClickHandler}></i>
                        </div>
                        <div className="col col-md-5 col-lg-3 col-xl-3 col-sm-4">
                            <div className="nav-icon">
                                <a href="#" className="right-side-menu ml-2"><BsTelephoneFill></BsTelephoneFill> {data?data.phone:""}</a>
                               

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"menu-bar "+ (isScrolled===true?"updated-menu":"")}>
            <div className="custom-container">
                <div className="d-flex">
                
                    <div className="me-auto">
                        <div className="category">
                            <ul className="menu">
                            <NavLink to="/" className="logo-text" >
                            <div className="logo-img-updated" >
                                <img src={data?data.logo:""} style={{maxHeight:"95%",maxWidth:"95%",borderRadius:"6%"}}  alt="logo"/>
                            </div>
                            
                            </NavLink>

                            
                                
                            
                            </ul>
                        </div>
                    </div>
                    <div className="">
                        <div className="category">
                        {
                            location.pathname==='/'?  <ul className="menu">
                            
                            <li className="menu-items menu-item-updated me-2"><NavLink to="/foreign-tiles" className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiAbacus className='menu-icon'></BiAbacus>  Foreign Tiles</NavLink></li>
                            <li className="menu-items menu-item-updated"><a href='#project' className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiVector className='menu-icon'></BiVector> Project</a></li>
                            <li className="menu-items menu-item-updated"><a href='#shopBy' className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiServer className='menu-icon'></BiServer> Category</a></li>
                            <li className="menu-items menu-item-updated"><a  href='#dealer' className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiAward className='menu-icon'></BiAward>Dealer</a></li>
                            <li className="menu-items menu-item-updated"><NavLink  to="/video-gallery" className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiVideo className='menu-icon'></BiVideo>Videos</NavLink></li>
                            <li className="menu-items menu-item-updated"><a  href='#contact' className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiEnvelope className='menu-icon'></BiEnvelope>Contact Us</a></li>
                            <li className="menu-items menu-item-updated"><a target='_blank'  href='https://dblceramics.com/en/room-visualizer' className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BsBoxSeam className='menu-icon'></BsBoxSeam>3D View</a></li>
                            
                        </ul>:<ul className="menu">
                            <li className="menu-items menu-item-updated me-2"><NavLink to="/foreign-tiles" className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiAbacus className='menu-icon'></BiAbacus>  Foreign Tiles</NavLink></li>
                                
                            
                            
                            <li className="menu-items menu-item-updated"><NavHashLink scroll={(el) => setTimeout(() => {
                                el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 2000)} smooth  to="/#project" className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiVector className='menu-icon'></BiVector>Project</NavHashLink></li>
                            <li className="menu-items menu-item-updated"><NavHashLink scroll={(el) => setTimeout(() => {
                                el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 2000)} smooth  to="/#shopBy" className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiServer className='menu-icon'></BiServer>Category</NavHashLink></li>
                            <li className="menu-items menu-item-updated"><NavHashLink scroll={(el) => setTimeout(() => {
                                el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 2000)} smooth  to="/#dealer" className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiAward className='menu-icon'></BiAward>Dealer</NavHashLink></li>
      <li className="menu-items menu-item-updated"><NavLink  to="/video-gallery" className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiVideo className='menu-icon'></BiVideo>Videos</NavLink></li>
                            <li className="menu-items menu-item-updated"><NavHashLink  scroll={(el) => setTimeout(() => {
                                el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 2000)} smooth to="/#contact" className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BiEnvelope className='menu-icon'></BiEnvelope>Contact Us</NavHashLink></li>
      <li className="menu-items menu-item-updated"><a target='_blank'  href='https://dblceramics.com/en/room-visualizer' className={"menu-items-text "+(isScrolled===true?"text-white":"")}><BsBoxSeam className='menu-icon'></BsBoxSeam>3D View</a></li>
                            
                        </ul>
                        }
                           
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </header>
    <div className="sideNavClose shadow-sm" ref={(div) =>{sideNavRef=div}}>
        <div className="sideMenuItem">
            <div className="sidemenu-content">
            
                <div className="mt-3 sidemenu-menu-items">
                {
                location.pathname==='/'? <ul>

                        <li><NavLink to="/foreign-tiles" ><BiAbacus className='menu-icon'></BiAbacus> Foreign Tiles</NavLink></li>
                        
                        <li><a href='#project' ><BiVector className='menu-icon'></BiVector> Project</a></li>
                        <li><a href='#shopBy' ><BiServer className='menu-icon'></BiServer> Category</a></li>
                        <li><a href='#dealer' ><BiAward className='menu-icon'></BiAward> Dealer</a></li>
                        <li><NavLink to="/video-gallery" ><BiVideo className='menu-icon'></BiVideo> Videos</NavLink></li>
                        <li><a href='#contact' ><BiEnvelope className='menu-icon'></BiEnvelope> Contact Us</a></li>
                        <li><a target='_blank'  href='https://dblceramics.com/en/room-visualizer' ><BsBoxSeam className='menu-icon'></BsBoxSeam> 3D View</a></li>

                    </ul>:<ul>
                        <li><NavLink to="/foreign-tiles" ><BiAbacus className='menu-icon'></BiAbacus> Foreign Tiles</NavLink></li>
                        
                        <li><NavHashLink scroll={(el) => setTimeout(() => {
                                el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 2000)} smooth to="/#project" ><BiVector className='menu-icon'></BiVector> Project</NavHashLink></li>
                        <li><NavHashLink scroll={(el) => setTimeout(() => {
                                el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 2000)} smooth to="/#shopBy" ><BiServer className='menu-icon'></BiServer> Category</NavHashLink></li>
                        <li><NavHashLink scroll={(el) => setTimeout(() => {
                                el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 2000)} smooth to="/#dealer" ><BiAward className='menu-icon'></BiAward> Dealer</NavHashLink></li>
      <li><NavLink to="/video-gallery" ><BiVideo className='menu-icon'></BiVideo> Videos</NavLink></li>
                        <li><NavHashLink scroll={(el) => setTimeout(() => {
                                el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 2000)} smooth to="/#contact" ><BiEnvelope className='menu-icon'></BiEnvelope> Contact Us</NavHashLink></li>
      <li ><a target='_blank'  href='https://dblceramics.com/en/room-visualizer' ><BsBoxSeam className='menu-icon'></BsBoxSeam> 3D View</a></li>
                    </ul>
            }
                </div>
            </div>
        </div>
    </div>
    <div className="" ref={(div) =>{overlayRef=div}} onClick={MenuBarClickHandler}></div>
    </ReactPlaceholder>
    </>
  )
}
