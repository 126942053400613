import React,{Suspense,lazy} from 'react'

import { useState } from 'react'



const FooterComponent = lazy(()=>import('../components/Footer/FooterComponent'));
const HeaderComponent = lazy(()=>import('../components/Header/HeaderComponent'));
const HomeSlider = lazy(()=>import('../components/Home/HomeSlider'));
const ExploreComponent = lazy(()=>import('../components/Home/ExploreComponent'));
const MiddleSliderComponent = lazy(()=>import('../components/Home/MiddleSliderComponent'));
const ShopByCategoryComponent = lazy(()=>import('../components/Home/ShopByCategoryComponent'));
const ShowCaseComponent = lazy(()=>import('../components/Home/ShowCaseComponent'));
const ContactComponent = lazy(()=>import('../components/Home/ContactComponent'));
import LazyLoader from '../components/MasterLayout/LazyLoader';
import LoaderComponent from '../components/Loader/LoaderComponent';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { useEffect } from 'react';
import { getBaseURL } from '../services/BaseURL'
import ForeignTilesSection from '../components/Home/ForeignTilesSection';
const BASE_URL = getBaseURL()
const axios = require('axios')
export default function HomePage() {
  let [ready,setReday]=useState(false)
  useEffect(()=>{
    let url = BASE_URL + 'slider'
      
      axios.get(url)
          .then(function(response) {
              if (response.status === 200) {
                setReday(true)
              } else {
                setReday(false)
              }
          })
          .catch(function(error) {
              setReday(false)
          });
          
  },[])
    
  
  return (
    <>
    <ReactPlaceholder customPlaceholder={<LoaderComponent></LoaderComponent>} ready={ready} rows={25} showLoadingAnimation  color='#E0E0E0'>
    <Suspense fallback={<LazyLoader/>}><HeaderComponent></HeaderComponent></Suspense>
    
    <main >
        <div className="container-fluid body">
            <div className="main-content">
            <Suspense fallback={<LazyLoader/>}>
                <HomeSlider></HomeSlider>
                <ExploreComponent></ExploreComponent>
                <ForeignTilesSection></ForeignTilesSection>
                <MiddleSliderComponent></MiddleSliderComponent>
                
                <ShopByCategoryComponent></ShopByCategoryComponent>
                <ShowCaseComponent></ShowCaseComponent>
                <ContactComponent></ContactComponent>
            </Suspense>
                
            </div>
        </div>
    </main>
    <Suspense fallback={<LazyLoader/>}>
      <FooterComponent></FooterComponent>
    </Suspense>
    </ReactPlaceholder>
    </>
  )
}
