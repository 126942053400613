import React from 'react'
import {TextBlock, MediaBlock, TextRow, RectShape, RoundShape} from 'react-placeholder/lib/placeholders';
export default function LoaderComponent() {
  return (
    <div className='my-awesome-placeholder' >
    <RectShape color='#e1e1e1' style={{width: "100%", height: "100vh"}}/>
    
        <div class="spinner-grow" role="status" style={{position:"absolute",top:"50%",left:"50%"}}>
            <span class="visually-hidden">Loading...</span>
        </div>
  </div>
  )
}
